
.invoiceDiv {
.header {
  display: flex;
  gap: 10px;
  align-items: center;
}
}

.userDetails {
  padding-bottom: 10px
}

.formField {
  flex: 1;
  padding: 5px;
}

@primary-color: #F49D3F;@layout-body-background: #ffffff;