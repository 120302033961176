.ant-menu.ant-menu-inline .ant-menu-title-content .menuitem {
    margin-left: 10px !important;
    color: #9CA3AF;
}
.ant-menu-sub.ant-menu-inline{
    background: transparent;
}
.ant-menu.ant-menu-root.ant-menu-inline{
    background: transparent;
}
.ant-layout .antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light{
    background: #000 !important;
}
.ant-layout .sidebar_dark{
    background: #000 !important;
}
.ant-layout-sider .ant-menu-submenu-expand-icon,
.ant-layout-sider .ant-menu-submenu-arrow {
    color: rgb(255 255 255 / 85%);
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-title-content .icon .menuitem{
    opacity: 0;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-only-child{
    padding-top: 25px;
    padding-bottom: 25px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FFAB0029;
    border-radius: 10px;
    color: #fff;  
}
.ant-menu-inline .ant-menu-item::after{
    content: none;
}
.ant-menu.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title{
    padding-top: 25px;
    padding-bottom: 25px;
}
.ant-menu-submenu-selected .ant-menu-submenu-title{
    background-color: #FFAB0029;
    border-radius: 10px;
    color: #FF9201;
    
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content .menuitem{
    color: #FF9201;
}
.ant-menu-inline .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content .menuitem{
    color: #FF9201;
}
.ant-menu.ant-menu-inline > .ant-menu-item{
    padding-top: 25px;
    padding-bottom: 25px;
}
.ant-layout-sider .anticon svg {
    color: #fff;
}
.ant-layout-sider .ant-menu-submenu-selected .anticon svg {
    color: #FF9201;
}
.ant-layout-sider-collapsed .ant-menu{
    background: unset !important;
}
.ant-layout-content.antd-pro-layouts-basic-layout-content {
    /* background: #f6f6f6; */
    background: #fbfbfb;
    margin: 0;
    padding: 30px;
    padding-top: 50px !important;
}
.ant-layout-sider-collapsed {
    padding: 0px !important;
}
.ant-layout-sider-collapsed .ant-menu-submenu-selected .ant-menu-submenu-title {
    border-radius: 0;
}
.ant-drawer-content-wrapper{
    width: 300px !important;
}

.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo a{
    display: block !important;
    position: absolute;
    left: 8px;
    top: 20px;
}