.fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  transition: width 0.2s;
  
}

@primary-color: #F49D3F;@layout-body-background: #ffffff;