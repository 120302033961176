@import '~antd/lib/style/themes/default.less';

@nav-header-height: @layout-header-height;

.logo {
  height: 80px;
  position: relative;
  line-height: @nav-header-height;
  // padding-left: (@menu-collapsed-width - 32px) / 2;
  transition: all 0.3s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  img {
    // position: absolute;
    // display: inline-block;
    // vertical-align: middle;
    height: 100px;
    margin-top: 8px;
    // transform: translateX(-50%);
  }
  h1 {
    color: white;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin: 0 0 0 12px;
    font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
  }
}

.sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
  &.fixSiderbar {
    position: fixed;
    top: 0;
    left: 0;
    :global(.ant-menu-root) {
      overflow-y: auto;
      height: ~'calc(100vh - @{nav-header-height})';
    }
  }
  &.light {
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    // background-color: white;
    .logo {
      //  background: white;
      // box-shadow: 1px 1px 0 0 @border-color-split;
      h1 {
        color: @primary-color;
      }
    }
    :global(.ant-menu-light) {
      border-right-color: transparent;
    }
  }
}

.icon {
  width: 14px;
  margin-right: 10px;
}

:global {
  .top-nav-menu li.ant-menu-item {
    height: @nav-header-height;
    line-height: @nav-header-height;
  }
  .drawer .drawer-content {
    background: #ffffff;
  }
  .ant-menu-inline-collapsed {
    & > .ant-menu-item .sider-menu-item-img + span,
    &
      > .ant-menu-item-group
      > .ant-menu-item-group-list
      > .ant-menu-item
      .sider-menu-item-img
      + span,
    &
      > .ant-menu-submenu
      > .ant-menu-submenu-title
      .sider-menu-item-img
      + span {
      max-width: 0;
      display: inline-block;
      opacity: 0;
    }
  }
  .ant-menu-item .sider-menu-item-img + span,
  .ant-menu-submenu-title .sider-menu-item-img + span {
    transition: opacity 0.3s @ease-in-out, width 0.3s @ease-in-out;
    opacity: 1;
  }
}

@primary-color: #F49D3F;@layout-body-background: #ffffff;