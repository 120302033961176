   .header {
        display: flex;
        gap: 10px;
        align-items: center; 
      }  
   .breadcrumb{
    padding: 7px; 
    font-size: 18px;
    color: orange;
  
   }
@primary-color: #F49D3F;@layout-body-background: #ffffff;