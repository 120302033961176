@import '~antd/lib/style/themes/default.less';

.content {
  margin: 24px 24px 0;
}

@media screen and (max-width: @screen-sm) {
  .content {
    margin: 24px 0 0;
  }
}

@primary-color: #F49D3F;@layout-body-background: #ffffff;